<template>
  <div>
    <v-dialog v-model="dialog" width="800">
      <v-card class="pa-5"
        ><div>
          <h4>Select Template {{ customerCode }}</h4>
          <v-text-field
            v-model="search"
            ref="search"
            style="width: 40%"
            class="mt-3"
            label="Search Title"
            color="red"
            dense
            outlined
          ></v-text-field>
          <v-divider />

          <v-simple-table class="tableHeight" fixed-header height="470px">
            <template v-slot:default>
              <!-- THEAD -->
              <thead>
                <tr>
                  <th class="text-center" v-text="'Title'" />
                  <th class="text-center" v-text="'Template'" />
                </tr>
              </thead>
              <tbody v-if="filterUsers.length">
                <tr
                  v-for="(val, index) in filterUsers"
                  :key="index"
                  @click="GetTemplate(val)"
                  style="cursor: pointer"
                >
                  <td class="text-center" v-text="val.Title" />
                  <td class="text-center" v-html="val.Template" />
                </tr>
              </tbody>

              <tbody v-else>
                <tr>
                  <td
                    class="text-center"
                    colspan="9"
                    v-html="'No Record Found'"
                    style="color: red; font-weight: bold; font-size: 20px"
                  />
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import allConnection from "../../functions/httpRequest/hrdappsAPI/allConnection";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      templates: [],
    };
  },
  props: {
    menu: {
      type: Boolean,
    },
    Kind: {
      type: Object,
    },
    AllTemplates: {
      type: Array,
    },
    customerCode: {
      type: String,
    },
  },
  watch: {
    menu(newValue) {
      if (!newValue) this.dialog = false;
      else this.dialog = true;
    },
    dialog(newVal) {
      // console.log(newVal);
      if (!newVal) {
        this.search = "";
        this.$emit("UpdateMenu", false);
      } else {
        this.Record();
      }
    },
  },
  computed: {
    filterUsers() {
      return this.templates.filter(
        (parent) =>
          parent.Title.toLowerCase().includes(this.search.toLowerCase()) &&
          parent.deletedDate == null
      );
    },
  },
  // created() {
  //   console.log(this.filterUsers);
  // },
  methods: {
    GetTemplate(val) {
      // console.log(val);
      if (val.To) {
        // let origTo = [];
        // origTo.push(JSON.parse(val.To));
        var convArr = JSON.parse(val.To);

        convArr.push("kikaku-plan@ichijo.co.jp");
        var getInx = convArr.indexOf("kikaku-plan@ichijo.co.jp");
        var emailArr = convArr[getInx];
        var newconArr = [];
        newconArr.push(emailArr);

        if (getInx > -1) {
          //NO need Kikaku
          convArr.splice(getInx, 1);
        }
        // convArr.splice(getInx, 1);
        // var emailPlanner = [];
        // emailPlanner.push(convArr);
        // TENJIJOU PLAN FOR INQUIRYKIND 001 ONLY
        if (this.customerCode.includes("0009999") && this.Kind.InquiryKind == "001")
          val.To = JSON.stringify(["tenjijou-inquiry@ichijo.co.jp"]);

        if (this.customerCode.includes("PS") || this.customerCode.includes("PL")) {
          // console.log(newconArr);
          val.To = JSON.stringify(newconArr);
          // let testTo = JSON.stringify(["kikaku-plan@ichijo.co.jp"]);
          // val.To = JSON.stringify(convArr[getInx].join);
          // val.To = JSON.stringify(["beforekakou02@gmail.com"])
          // console.log(convArr);
        }
        // else {
        //   console.log(origTo);
        // }
      }
      // console.log(val.Cc);
      // console.log(JSON.parse(val.Cc));

      this.$emit("GetTemplate", val);
      val.To = JSON.stringify(convArr);
      // val.To = JSON.stringify(emailPlanner);
      this.dialog = false;
    },

    Record() {
      this.templates = this.AllTemplates.filter(
        (el) =>
          el.InquiryKindID == this.Kind.InquiryKind && el.JobKindID == this.Kind.JobKind
      );
      // console.log(this.templates);
    },
  },
};
</script>

<style lang="scss">
.list {
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
</style>
