<template>
  <div>
    <!-- FOR SENDING FOLLOWUP -->
    <!-- v-if="this.dialog = this.conditionpreview = false"  -->
    <v-dialog v-model="dialog" max-width="700px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title class="mt-n5">Send Followup</v-card-title>
        <v-divider class="mb-3" />
        <!-- SENDER EMAIL -->
        <v-text-field
          v-model="toSend.SenderEmail"
          label="Sender Email"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- RECIPIENT EMAIL -->
        <div>
          <v-row class="mt-n5">
            <v-col>
              <v-combobox
                v-model="toSend.RecipientEmail"
                :items="selectedEmail"
                label="Recipient Email"
                color="red"
                dense
                outlined
                filled
                multiple
                disabled
              />
            </v-col>
            <v-col>
              <v-combobox
                v-model="toSend.CcEmail"
                label="Cc Email"
                color="red"
                dense
                outlined
                filled
                multiple
                disabled
              />
            </v-col>
          </v-row>
          <v-row class="mt-n5 mb-2">
            <v-col>
              <!-- editdDuedate 
             
             v-model="editDueDate.dueDate"
             -->
              <!-- <v-text-field
                type="date"
                v-model="editDueDate.dueDate" 
                :value="this.ciInfo.Due"
                color="#df6464"
                :min='new Date().toISOString().slice(0, 10)'
                label = 'Due Date'
                dense
              ></v-text-field> -->
              <!-- DATE FROM -->

              <v-menu
                class="text-center"
                content-class="elevation-0"
                :close-oncontent-click="false"
                transition="scale-transition"
                offet-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editDueDate.dueDate"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!toSend.Template"
                    color="red"
                    label="Due Date"
                    filled
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editDueDate.dueDate"
                  :value="this.ciInfo.Due"
                  :min="new Date().toISOString().slice(0, 10)"
                  color="#df6464"
                />
              </v-menu>

              <v-btn
                @click="ciDialog = !ciDialog"
                color="#25bbf7"
                block
                style="color: white"
                >CI Info</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <!-- FOLLOWUP TYPE -->
        <p
          class="mt-n3 mb-n3"
          style="color: grey; font-size: 16px"
          v-text="`Followup Type`"
        />
        <v-radio-group
          @change="CheckFollowup(followupValue)"
          v-model="followupValue"
          ref="radioFollowup"
          dense
          row
        >
          <v-radio
            v-for="(val, i) in followupType"
            :key="i"
            :label="val"
            :value="i + 1"
            color="red"
            :disabled="
              CheckFollowupStatus(i + 1, followupType.length, ciInfo.FollowupStatus)
            "
          />
          <v-btn
            @click="addTemplateDialog = !addTemplateDialog"
            color="info"
            width="52%"
            small
            outlined
          >
            <v-icon>mdi-plus</v-icon> Add template</v-btn
          >
        </v-radio-group>
        <!-- TEMPLATE -->

        <v-textarea
          v-model="toSend.Template"
          :disabled="!selectedFollowup"
          color="red"
          label="Template"
          filled
          outlined
          dense
          clearable
        />
        <v-divider class="mb-3 mt-n1" />
        <!-- ACTION -->
        <v-row>
          <v-col>
            <!-- v-if this.conditionpreview = true then followupbutton is true -->
            <v-btn
              :disabled="!this.conditionpreview ? true : false"
              @click="sendFollowup(toSend)"
              block
              class="mt-2"
              color="#00cba3"
              outlined
              ><v-icon>mdi-email-send-outline</v-icon>Send Followup</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              :disabled="!toSend.Template ? true : false"
              @click="ViewPreview()"
              block
              class="mt-2"
              color="#00cba3"
              outlined
              ><v-icon>mdi-file-find</v-icon>Peview</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <!-- DIALOG FOR PREVIEW  -->
      <v-dialog v-model="FollowUp" max-width="800px">
        <v-card class="pa-10" style="background-color: #ffffff">
          <v-divider class="mb-2" />
          <v-container>
            <v-textarea
              rows="1"
              readonly
              flat
              solo
              auto-grow
              dense
              :value="this.templatePreview"
            ></v-textarea>
            <p class="ml-3">
              -----Original Message-----<br />
              From: {{ ciInfo.SenderName }}<br />
              Due: {{ this.editDueDate.dueDate }}<br />
              Sent: {{ ciInfo.Send }} {{ ciInfo.ReceivedTime }}<br />
              To: {{ ciInfo.HandleStaff }}<br />
              Cc: {{ ciInfo.SubHandleStaff || "" }}<br />
              Subject: {{ ciInfo.Subject }}<br /><br />

              <!-- Subject: {{subject}}<br><br> -->
            </p>
            <v-textarea
              flat
              readonly
              auto-grow
              solo
              :value="this.contentPreview"
            ></v-textarea>
          </v-container>
          <v-divider class="mb-2" />
        </v-card>
      </v-dialog>
    </v-dialog>

    <!-- DIALOG FOR ADDING FOLLOWUP TEMPLATE -->
    <v-dialog
      v-model="addTemplateDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-card-title class="mt-n5">Add Followup Template</v-card-title>
        <v-divider class="mb-3" />

        <!-- DEPARTMENT -->
        <v-text-field
          v-model="toAdd.Department"
          label="Department"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- SECTION -->
        <v-text-field
          v-model="toAdd.Section"
          label="Section"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- TEAM -->
        <v-text-field
          v-model="toAdd.Team"
          label="Team"
          color="red"
          dense
          outlined
          filled
          disabled
        />

        <!-- FOLLOWUP TYPE -->
        <p
          class="mt-n3 mb-n3"
          style="color: grey; font-size: 16px"
          v-text="`Followup Type`"
        />
        <v-radio-group v-model="toAdd.FollowupType" ref="addRadioFollowup" dense row>
          <v-radio
            v-for="(val, i) in followupType"
            :key="i"
            :label="val"
            :value="i + 1"
            color="red"
          />
        </v-radio-group>

        <!-- TITLE -->
        <v-text-field
          v-model="toAdd.Title"
          label="Title"
          color="red"
          dense
          outlined
          filled
        ></v-text-field>
        <!-- TEMPLATE -->
        <v-textarea
          v-model="template"
          @keyup="toAdd.Template = template.split('\n').join('<br>')"
          color="red"
          label="Template"
          filled
          outlined
          dense
        />
        <v-divider />
        <!-- ACTION -->
        <v-btn @click="AddTemplate()" class="mt-2" color="#00cba3" block outlined
          ><v-icon>`mdi-email-plus-outline</v-icon>Insert</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- FOR SELECTING FOLLOWUP -->
    <v-dialog
      v-model="selectFollowupDialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-5">
        <v-card-title class="mt-n5">Select Template</v-card-title>
        <v-divider class="mb-3" />
        <v-list>
          <v-list-item-group color="#df6464" width="100%">
            <v-list-item v-for="(val, index) in followupTemplate" :key="index">
              <v-list-item-content
                @click="
                  (toSend.Template = val.Template.split('<br>').join('\n')),
                    (selectFollowupDialog = !selectFollowupDialog)
                "
              >
                <v-list-item-title
                  class="text-center"
                  style="font-weight: bold; font-size: 17px"
                >
                  {{ val.Title }}
                </v-list-item-title>
                <v-divider class="mt-2" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- FOR SELECTING CI INFO -->
    <v-dialog v-model="ciDialog" max-width="850px" transition="dialog-transition">
      <v-card class="pa-5">
        <v-card-title primary-title>Customer Information</v-card-title>
        <v-divider />
        <div class="" style="max-height: 700px">
          <v-row>
            <v-col>
              <p class="subtitle-1 mb-n3">From</p>
              <v-checkbox
                v-model="EmailAddress"
                @change="AddCustomerInformation(EmailAddress, 'RecipientEmail')"
                v-for="(val, i) in EmailInformation"
                :key="i"
                :value="val"
                class="mb-n7"
                :label="`${i.split('E')[0]} ( ${val} )`"
                color="#df6464"
                multiple
              ></v-checkbox
            ></v-col>
            <v-divider vertical />
            <v-col>
              <p class="subtitle-1 mb-n3">Cc</p>
              <v-checkbox
                v-model="CcAddress"
                @change="AddCustomerInformation(CcAddress, 'CcEmail')"
                v-for="(val, i) in EmailInformation"
                :key="i"
                :value="val"
                class="mb-n7"
                :label="`${i.split('E')[0]} ( ${val} )`"
                color="#df6464"
                multiple
              ></v-checkbox
            ></v-col>
          </v-row>
        </div>
        <v-divider />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import objects from "../../functions/alert";
import FollowupOverdueTable from "../../functions/httpRequest/ExternalConnection/FollowupOverdueTable";
import InquiryStatusTable from "../../functions/httpRequest/ExternalConnection/InquiryStatusTable";
import allConnection from "../../functions/httpRequest/hrdappsAPI/allConnection";
import hrdSql from "../../functions/httpRequest/ExternalConnection/hrdSql";
import EmailSending from "../../functions/email";
import store from "../../store";
import moment from "moment";

export default {
  data() {
    return {
      editDueDate: {},
      conditionpreview: false,
      contentPreview: "",
      FollowUp: "",
      selectedFollowup: null,
      dialog: false,
      ciDialog: false,
      addTemplateDialog: false,
      selectFollowupDialog: false,
      followupValue: "",
      template: "",
      EmailInformation: {},
      toFollowup: {},
      toSend: { CcEmail: [] },
      toAdd: {},
      EmailAddress: [],
      CcAddress: [],
      selectedEmail: [],
      followupTemplate: [],
      followupType: ["First", "Second", "Third"],
      templatePreview: "",
    };
  },

  props: {
    userRecord: {
      type: Object,
    },
    ciInfo: {
      type: Object,
    },
  },

  watch: {
    dialog(newval) {
      if (newval) {
        // console.log(newval);
        this.editDueDate.dueDate = this.ciInfo.Due;
        //Initial Due date
        delete this.toSend.Template;
        for (let x in this.toFollowup) this.$set(this.toSend, x, this.toFollowup[x]);
      }
      this.conditionpreview = false; //ENABLE SEND PREVIEW
    },
    addTemplateDialog(newval) {
      if (newval) {
        this.toAdd.Department = this.userRecord.Department;
        this.toAdd.Section = this.userRecord.Section;
        this.toAdd.Team = this.userRecord.Team;
      }
    },
    ciDialog(newval) {
      if (newval && !this.EmailInformation.length) {
        this.GetCiInformation(this.ciInfo.CustomerCode);
      }
    },
  },
  computed: {},

  methods: {
    // Preview
    ViewPreview() {
      this.FollowUp = true;
      this.templatePreview = this.toSend.Template.split("<br>").join("<\n>");
      this.contentPreview = this.ciInfo.ContentOfInquiry.split("<br>").join("\n");
      this.conditionpreview = true;
      // console.log(this.templatePreview);
      // console.log(this.FollowUp);
    },
    // end of Preview
    /**
     * * FOR SENDING FOLLOWUP
     * @param {Object} obj to be send in email
     */

    sendFollowup({ ...obj }) {
      const Cc = "CcEmail" in obj ? 4 : 3;
      // CHECK VALIDATION
      if (Object.keys(obj).length < Cc)
        return this.$toast.error("Fill-up All Fields", objects.alert);

      this.$confirm({
        title: "Followup",
        message: "Confirm to send followup",
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async (confirm) => {
          if (confirm) {
            // ASSIGN
            obj.Template = obj.Template.split("\n").join("<br>");
            obj.RecipientEmail = obj.RecipientEmail.join(",");
            obj.CcEmail.push(obj.SenderEmail);
            obj.CcEmail = obj.CcEmail.join(",");

            // DUEDATE
            // this.ciInfo.Due
            const due =
              !this.editDueDate.dueDate || this.editDueDate.dueDate == "Invalid date"
                ? ""
                : `返信期限${this.editDueDate.dueDate.split("-")[1]}月${
                    this.editDueDate.dueDate.split("-")[2]
                  }日`;

            // EMAIL SUBJECT
            const subject = `【${this.ciInfo.InquiryKind}】 ${
              this.ciInfo.Subject.split("/")[this.ciInfo.Subject.split("/").length - 1]
            } 《${this.ciInfo.CustomerCode}》${due} `;

            // FORWARD STUCTURE MESSAGE
            const forwardMessage = `${obj.Template}<br><br>${this.OriginalMessage(
              this.ciInfo,
              subject
            )}`;

            // ATTACHMENT               `${this.userRecord.FullName} < ${store.state.userinfo.Email}>`,
            const attachment = this.ciInfo.Attachment
              ? this.ciInfo.Attachment.split(",").map((el) => `${this.ciInfo.Path}/${el}`)
              : [];

            const updateStatus = {
              ConfirmationID: this.ciInfo.ConfirmationID,
              FollowupStatus: this.selectedFollowup,
            };
            await InquiryStatusTable.Update(updateStatus);
            // SENDING EMAIL
            await EmailSending.sendCorrection(
              this.ciInfo.ConfirmationID,
              this.ciInfo.Status,
              this.ciInfo.Department,
              store.state.userinfo.EmployeeCode,
              moment(this.ciInfo.DueDate).format("YYYY-MM-DD"),
              `${this.userRecord.FullName} < ${obj.SenderEmail}>`,
              obj.SenderEmail,
              // "noreply@hrd-s.com",
              obj.RecipientEmail.split(","),
              obj.CcEmail,
              forwardMessage,
              this.ciInfo.CustomerCode,
              `Fw:${subject}`,
              attachment || []
            )
              .then(() => {
                this.dialog = false;
                this.toSend = { CcEmail: [] };
                this.$toast.warning("Sending follow up please wait", objects.alert);
              })
              .catch(() =>
                this.$toast.error("Something wrong while sending.", objects.alert)
              );
            this.selectedFollowup = null;
            this.$emit("closeDialog");
          }
        },
      });
    },

    /**
     * * ACTUAL MESSAGE INFORMATION
     * @param {String} ciInfo additional information for followup
     * @param {String} subject  additional information for followup
     */

    OriginalMessage(ciInfo, subject) {
      return `
             -----Original Message-----<br>
          From: ${ciInfo.SenderName}<br>
          Sent: ${ciInfo.Send} ${ciInfo.ReceivedTime}<br>
          To: ${ciInfo.HandleStaff}<br>
          Cc: ${ciInfo.SubHandleStaff || ""}<br>
          Subject: ${subject}<br><br>

          ${ciInfo.ContentOfInquiry}
      `;
    },

    /**
     * * RECIPIENT EMAIL
     * @params Email; email recipient
     */

    AddCustomerInformation(email, emailType) {
      this.toSend[emailType] =
        emailType == "RecipientEmail" ? [...this.toFollowup.RecipientEmail] : [];

      for (let val of email) {
        const checkDuplicate = this.toSend[emailType].some((el) => val == el);
        if (!checkDuplicate) this.toSend[emailType].push(val);
      }
    },

    /**
     * * ADDING USER TEMPLATE
     */

    AddTemplate() {
      // FOR VALIDATION
      if (Object.keys(this.toAdd).length !== 6)
        return this.$toast.error("Fill-up All Fields", objects.alert);

      // PASS DATA
      const data = this.toAdd;

      // FOR SAVING IN DATABASE
      FollowupOverdueTable.Post(data).then(() => {
        this.$toast.success("Successfully Added", objects.alert);
        this.addTemplateDialog = !this.addTemplateDialog;
      });
    },

    /**
     * * GETTING ALL FOLLOWUP IN DATABASE
     * @param {Int} type which followup type selected
     */

    CheckFollowup(type) {
      if (!this.followupValue) return;

      //   QUERY TO PASS IN DATABASE
      const obj = {
        Department: this.userRecord.Department,
        Section: this.userRecord.Section,
        Team: this.userRecord.Team,
        FollowupType: type,
      };

      // CONNECTION
      FollowupOverdueTable.Get(obj).then((res) => {
        // FOR NO RECORD
        if (!res.data.length) {
          this.selectedFollowup = null;
          return this.$toast.error("No Record Found.", objects.alert);
        }

        this.selectedFollowup = type;
        this.followupTemplate = res.data;
        this.selectFollowupDialog = !this.selectFollowupDialog;
      });

      // RESET RADIO BUTTON
      setTimeout(() => this.$refs.radioFollowup.reset(), 2000);
    },

    /**
     * * EMITTED DATA FROM SearchConfirmation.vue
     * @params followupData; All followup data emitted from SearchConfirmation.vue
     */

    GetData(followupData) {
      this.toFollowup = followupData;
      this.dialog = !this.dialog;
    },

    /**
     * * EXTRACT ALL CI INFORMATION
     * @param {String} controlNumber; control number to get specific ci info
     */

    async GetCiInformation(controlNumber) {
      let email = {};
      //GET EMAIL INFORMATION
      await hrdSql
        .ConstructionEmailInformation(controlNumber)
        .then((res) => (email = res.data));

      await allConnection
        .CustomerInformation(controlNumber)
        .then(async (customerInfo) => {
          await allConnection
            .ConstBranchOffice(customerInfo[0].ConstExhibitionCode)
            .then((exhibitionInfo) => {
              this.$set(
                email,
                "ConstBranchEmail",
                exhibitionInfo[0].MailAddress || exhibitionInfo[0].MailAddress2
              );

              email.ConstBranch = exhibitionInfo[0].ContractExhibitionName;
            });
        });

      await allConnection.DesignOffice(controlNumber).then((designOffice) => {
        this.$set(email, "DesignOfficeEmail", designOffice[0].MailAddress);

        email.DesignOffice = designOffice[0].TraderName1;
      });

      for (let x in email)
        if (x.includes("Email"))
          if (email[x]) this.$set(this.EmailInformation, x, email[x]);
    },

    /**
     * CHECK FOR FOLLOWUP IF ALREADY SEND
     * @param {Int} number RadioButton Value
     * @param {Int} FollowupLength Overall length of Array
     * @param {Boolean} status Check the status of followup
     * @returns Boolean value
     */

    CheckFollowupStatus(number, FollowupLength, status) {
      if (!status) {
        if (number === 1) return false;
        else return true;
      }
      for (let i = 1; i <= FollowupLength; i++) return number !== status + 1;
    },
  },
};
</script>
