import axios from "axios";
import keys from "../../alert";

export default {
  //*******************************************//
  //  GET REQUEST IN STOP ASSESSMENT TABLE    //
  //******************************************//
  Get(data) {
    return axios.get(`${keys.auth.BaseUrl}/stopAssessment`, {
      headers: { "x-api-key":"ZUe7gbLExb2OyqUHGdwXT9r8whee39ivabgYIXdJ"},
      data: data
    });
  },
};
