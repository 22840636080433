<template>
  <v-container flat class="pa-0">
    <div class="mb-2">
      <v-menu
        transition="slide-x-transition"
        offset-y
        :nudge-width="400"
        :close-on-content-click="false"
      >
        <!--        :close-on-click="false"
      v-model="OpenUSerIfo" -->
        <template v-slot:activator="{ on, attrs }">
          <span style="color: #666666; font-weight: bold" dark v-bind="attrs" v-on="on">
            <v-icon color="red">mdi-cog-outline</v-icon>User Information
          </span>
        </template>
        <!-- USER INFORMATION -->
        <v-list>
          <v-list-item-title class="ma-2" style="font-weight: bold">
            Update Information
          </v-list-item-title>
          <!-- EMPLOYEE NAME -->
          <span class="text--secondary ml-4">Name</span>
          <v-list-item>
            <v-text-field :value="fullName" solo dense disabled></v-text-field>
          </v-list-item>

          <span class="text--secondary ml-4">Department</span>
          <v-list-item>
            <v-text-field :value="department" solo dense disabled></v-text-field>
          </v-list-item>

          <span class="text--secondary ml-4">Section</span>
          <v-list-item>
            <v-text-field :value="section" solo dense disabled></v-text-field>
          </v-list-item>

          <span class="text--secondary ml-4">Team</span>
          <v-list-item>
            <v-text-field :value="team" solo dense disabled></v-text-field>
          </v-list-item>
          <v-list-item>
            <!-- UPDATE USER INFO -->
            <v-btn
              @click="updateUserInformationOrg"
              :loading="Loader"
              color="success"
              block
              outlined
              >Auto Update Organization</v-btn
            >
          </v-list-item>
          <!-- EMAIL -->
          <span class="text--secondary ml-4">Email</span>
          <v-list-item>
            <v-text-field
              v-model="information.Email"
              :disabled="Loader"
              solo
              dense
            ></v-text-field>
          </v-list-item>
          <!-- Signature -->
          <span class="text--secondary ml-4">Signature</span>
          <v-list-item>
            <v-text-field
              v-model="information.Signature"
              :disabled="Loader"
              solo
              dense
            ></v-text-field>
          </v-list-item>
          <v-list-item class="mb-n3 mt-n8">
            <span
              style="color: red; font-weight: bold; font-size: 11px"
              v-text="'Note: Update all fields to send confirmation.'"
            />
          </v-list-item>
          <!-- ACTION -->
          <v-list-item>
            <v-btn
              @click="UpdateUserInformation"
              :loading="Loader"
              color="info"
              block
              outlined
              >Update</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- For no Signature and Email also to avoid mutating -->
    <v-container flat class="pa-0" v-show="hide">
      <div class="mb-2">
        <v-menu
          transition="slide-x-transition"
          offset-y
          :nudge-width="400"
          :close-on-content-click="false"
          :close-on-click="false"
          v-model="OpenUSerIfo"
        >
          <template v-slot:activator="{ on, attrs }">
            <span style="color: #666666; font-weight: bold" dark v-bind="attrs" v-on="on">
              <v-icon color="red">mdi-cog-outline</v-icon>User Information
            </span>
          </template>
          <!-- USER INFORMATION -->
          <v-list>
            <v-list-item-title class="ma-2" style="font-weight: bold">
              Insert Information
            </v-list-item-title>
            <!-- EMPLOYEE NAME -->
            <span class="text--secondary ml-4">Name</span>
            <v-list-item>
              <v-text-field :value="fullName" solo dense disabled></v-text-field>
            </v-list-item>
            <!-- EMAIL -->
            <span class="text--secondary ml-4">Email</span>
            <v-list-item>
              <v-text-field
                v-model="information.Email"
                :disabled="Loader"
                solo
                dense
              ></v-text-field>
            </v-list-item>
            <!-- Signature -->
            <span class="text--secondary ml-4">Signature</span>
            <v-list-item>
              <v-text-field
                v-model="information.Signature"
                :disabled="Loader"
                solo
                dense
              ></v-text-field>
            </v-list-item>
            <v-list-item class="mb-n3 mt-n8">
              <span
                style="color: red; font-weight: bold; font-size: 11px"
                v-text="'Note: Update all fields to send confirmation.'"
              />
            </v-list-item>
            <!-- ACTION -->
            <v-list-item>
              <v-btn
                @click="UpdateUserInformation"
                :loading="Loader"
                color="info"
                block
                outlined
                >Update</v-btn
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-container>
  </v-container>
</template>
<script>
import store from "@/store";
// import objects from "../../functions/alert";
import Swal from "sweetalert2";
import correctionRequestTable from "../../functions/httpRequest/ExternalConnection/CorrectionRequestTable";

export default {
  props: ["OpenUSerIfo"],
  data() {
    return {
      Loader: false,
      fullName: store.state.userinfo.FullName,
      department: store.state.userinfo.Department,
      section: store.state.userinfo.Section,
      team: store.state.userinfo.Team,
      EmployeeCode: store.state.userinfo.EmployeeCode,
      information: {},
      hide: false,
    };
  },
  created() {
    this.getUserInformation();
  },
  methods: {
    // UPDATE USER INFO

    updateUserInformationOrg() {
      this.Loader = true;
      correctionRequestTable.getUpdatedUserInfo(this.EmployeeCode).then(async (res) => {
        this.Loader = false;
        this.department = res.data[0].DepartmentName;
        this.section = res.data[0].SectionName;
        this.team = res.data[0].TeamName;
        this.information.Department = res.data[0].DepartmentName;
        this.information.Section = res.data[0].SectionName;
        this.information.Team = res.data[0].TeamName;
      });
    },
    //***********************//
    // UPDATE OR INSERT USER //
    //***********************//
    UpdateUserInformation() {
      this.Loader = true;
      this.information.EmployeeCode = this.EmployeeCode;
      this.information.EmployeeName = this.fullName;

      correctionRequestTable.GetSpecificUser(this.EmployeeCode).then(async (res) => {
        const user = res.data;
        // INSERT IF NOT EXIST
        if (user.length == 0)
          return await correctionRequestTable
            .Insert(this.information)
            .then(() => Success(false));
        // UPDATE IF EXIST
        await correctionRequestTable.Update(this.information).then(() => Success(true));
      });

      // AFTER UPDATING/INSERTING
      const Success = (bool) => {
        bool ? this.getUserInformation() : "";
        this.$emit("updateUser");
        // this.$toast.success("Update Succesfully", objects.alert);
        this.Loader = false;

        Swal.fire({
          title: "Update Successfully",
          text: "Please LOG-IN Again",
          allowOutsideClick: false,
          icon: "success",
          imageWidth: 450,
          imageHeight: 70,
        }).then((result) => {
          if (result.isConfirmed) {
            this.logout();
          }
        });
      };
    },

    logout() {
      // this.$socket.emit("newUpdate", {
      //   hrdInquiry: this.id,
      //   hrdInquiryStatus: "LOGOUT",
      // });
      localStorage.clear();
      process.env.NODE_ENV == "development"
        ? window.open(`http://${window.location.host}/login`, "_self") //FOR DEVELOPMENT MODE
        : window.open("https://inquiry.hrd-s.com/login", "_self") //FOR PRODUCTION MODE
        ? window.open(`http://${window.location.host}/login`, "_self") //FOR DEVELOPMENT MODE
        : window.open(
            `http://inquirytestbucket.hrd-s.com.s3-website-ap-southeast-1.amazonaws.com/login`,
            "_self"
          ); //FOR DEVELOPMENT MODE
    },

    //***********//
    // GET USER  //
    //***********//
    getUserInformation() {
      // console.log(this.EmployeeCode,"emp code");
      correctionRequestTable.GetSpecificUser(this.EmployeeCode).then((res) => {
        if(res.data.length > 0){
          const data = res.data;
          this.fullName = data.length == 0 ? this.fullName : res.data[0].EmployeeName;
          this.information = {
            Email: !data[0].Email ? "" : res.data[0].Email,
            Signature: !data[0].Signature ? "" : res.data[0].Signature,
          };
        }
      });
    },
  },
};
</script>
